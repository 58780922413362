<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllContracts.gql')"
    :variables="{}"
    @result="doneLoadingContracts"
  >
    <template slot-scope="{ result: { data, error } }">
      <div class="lsg-cabinet">
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div v-if="$store.state.me.role === 'partner'" class="main__content">
          <div v-if="loadingData" class="messages__status loading">Идёт загрузка данных...</div>
          <div v-if="error" class="messages apollo error">{{ error.message }}</div>
          <div v-if="!loadingData && !error && data" class="apply">
            <div class="apply__header">
              <div class="apply__header__item">
                <div class="bn--client__header__container" @click="changeStep(1)">
                  <div :class="{ 'ellipse--active': $store.state.step >= 1 }" class="ellipse">
                    <UserPlusIcon :class="{ 'bn--client__header__item--active': $store.state.step >= 1 }" class="bn--client__header__item"/>
                  </div>
                  <div :class="{ 'bn--client__header__item--active': step >= 1 }" class="bn--client__header__textitem">
                    <strong>Контрагент</strong>
                  </div>
                </div>
                <LineItem class="bn--client__header__lineitem"/>
                <div class="bn--client__header__container" @click="changeStep(2)">
                  <div :class="{ 'ellipse--active': step >= 2 }" class="ellipse">
                    <CarbonUserAvatarIcon :class="{ 'bn--client__header__item--active': step >= 2 }" class="bn--client__header__item"/>
                  </div>
                  <div :class="{ 'bn--client__header__item--active': step >= 2 }" class="bn--client__header__textitem">
                    <strong>Контактное лицо</strong>
                  </div>
                </div>
                <LineItem class="bn--client__header__lineitem"/>
                <div class="bn--client__header__container">
                  <div :class="{ 'ellipse--active': $store.state.step >= 3 }" class="ellipse">
                    <CheckIcon :class="{ 'bn--client__header__item--active': $store.state.step >= 3 }" class="bn--client__header__item"/>
                  </div>
                  <div :class="{ 'bn--client__header__item--active': $store.state.step >= 3 }" class="bn--client__header__textitem">
                    <strong>Подать заявку</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="apply__body">
              <form class="apply__body__form">
                <div class="apply__body__title">
                  <h3>{{ step === 1 ? companyForm.fullname.value || pageTitle[step - 1] : pageTitle[step - 1] }}</h3>
                </div>
                <div v-if="$store.state.step === 1" class="apply__body__counterparty _row">
                  <div class="_col-2">
                    <!-- <label class="apply__body__item">
                      <span>Наименование:</span>
                      <input type="text" v-model="companyForm.name.value" @change="companyForm.name.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.name.error}">
                    </label> -->
                    <!-- <label class="apply__body__item">
                      <span>Полное наименование:</span>
                      <input type="text" v-model="companyForm.fullname.value" @change="companyForm.fullname.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.fullname.error}">
                    </label> -->
                    <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: ИНН состоит из 10 цифр'">ИНН:</span>
                      <input disabled type="number" v-model="modelPartnerInn" class="apply__body__item__input" :class="{'not-valid': companyForm.inn.error}">
                    </label>
                    <!-- <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: КПП состоит из 9 цифр'">КПП:</span>
                      <input type="number" v-model="modelPartnerKpp" class="apply__body__item__input" :class="{'not-valid': companyForm.kpp.error}">
                    </label>
                    <label class="apply__body__item">
                      <span>Банк:</span>
                      <input type="text" v-model="companyForm.bank.value" @change="companyForm.bank.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.bank.error}">
                    </label> -->
                    <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: БИК состоит из 9 цифр'">БИК:</span>
                      <input type="number" v-model="modelPartnerBik" class="apply__body__item__input" :class="{'not-valid': companyForm.bik.error}">
                    </label>
                    <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: Расчетный счет состоит из 20 цифр'">Расчетный счет:</span>
                      <input type="number" v-model="modelPartnerRs" class="apply__body__item__input" :class="{'not-valid': companyForm.rs.error}">
                    </label>
                  </div>
                  <div class="_col-2">
                    <!-- <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: Корреспондентский счет состоит из 20 цифр'">Корреспондентский счет:</span>
                      <input type="number" v-model="modelPartnerKs" class="apply__body__item__input" :class="{'not-valid': companyForm.ks.error}">
                    </label> -->
                    <label class="apply__body__item">
                      <span>Юридический адрес:</span>
                      <input disabled type="text" v-model="companyForm.adresU.value" @change="companyForm.adresU.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.adresU.error}">
                    </label>
                    <!-- <label class="apply__body__item">
                      <span>Фактический адрес:</span>
                      <input type="text" v-model="companyForm.adresF.value" @change="companyForm.adresF.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.adresF.error}">
                    </label>
                    <label class="apply__body__item">
                      <span>Почтовый адрес:</span>
                      <input type="text" v-model="companyForm.adresD.value" @change="companyForm.adresD.error = false" class="apply__body__item__input" :class="{'not-valid': companyForm.adresD.error}">
                    </label> -->
                    <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: номер телефона должен состоять исключительно из цифр'">Телефон:</span>
                      <input type="number" v-model="modelPartnerPhone" class="apply__body__item__input" :class="{'not-valid': companyForm.phone.error}">
                    </label>
                    <label class="apply__body__item">
                      <span v-tooltip="'Подсказка: пример электронной почты qwerty@qwerty.ru'">Email:</span>
                      <input type="text" v-model="modelPartnerEmail" class="apply__body__item__input" :class="{'not-valid': companyForm.email.error}">
                    </label>
                  </div>
                </div>
                <div v-if="$store.state.step === 2" class="apply__body__contact">
                  <div class="apply__body__contact__content">
                    <label class="apply__body__item">
                      <span>ФИО:</span>
                      <input v-if="contactForm.name.value.replace(/\s/g, '').length === 0" type="text" v-model="contactForm.name.value" @change="contactForm.name.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.name.error}">
                      <input v-else disabled type="text" v-model="contactForm.name.value" class="apply__body__item__input">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-3">
                        <span>Дата рождения:</span>
                        <input type="date" v-model="contactForm.birthday.value" @change="contactForm.birthday.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.birthday.error}">
                      </label>
                      <label class="apply__body__item _col-3">
                        <span>Должность:</span>
                        <input v-if="contactForm.post.value.length === 0" type="text" v-model="contactForm.post.value" @change="contactForm.post.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.post.error}">
                        <input v-else disabled type="text" v-model="contactForm.post.value" class="apply__body__item__input">
                      </label>
                      <label class="apply__body__item _col-3">
                        <span>Основа полномочий:</span>
                        <input type="text" v-model="contactForm.basis.value" @change="contactForm.basis.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.basis.error}">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Место рождения:</span>
                      <input type="text" v-model="contactForm.birthplace.value" @change="contactForm.birthplace.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.birthplace.error}">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-2">
                        <span v-tooltip="'Подсказка: серия и номер паспорта вводится без пробела'">Серия и номер паспорта:</span>
                        <input type="number" v-model="modelContactPassportId" class="apply__body__item__input" :class="{'not-valid': contactForm.passportId.error}">
                      </label>
                      <label class="apply__body__item _col-2">
                        <span>Дата выдачи паспорта:</span>
                        <input type="date" v-model="contactForm.passportDate.value" @change="contactForm.passportDate.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.passportDate.error}">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Место выдачи паспорта:</span>
                      <input type="text" v-model="contactForm.passportPlace.value" @change="contactForm.passportPlace.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.passportPlace.error}">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-2">
                        <span>Код подразделения:</span>
                        <input type="text" v-model="contactForm.passportCode.value" @change="contactForm.passportCode.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.passportCode.error}">
                      </label>
                      <label class="apply__body__item _col-2">
                        <span v-tooltip="'Подсказка: ИНН состоит из 12 цифр'">ИНН:</span>
                        <input type="number" v-model="modelContactInn" class="apply__body__item__input" :class="{'not-valid': contactForm.inn.error}">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Адрес для информирования:</span>
                      <input type="text" v-model="contactForm.adresD.value" @change="contactForm.adresD.error = false" class="apply__body__item__input" :class="{'not-valid': contactForm.adresD.error}">
                    </label>
                    <div class="_row _row--apply _row">
                      <label class="apply__body__item _col-2">
                        <span v-tooltip="'Подсказка: номер телефона должен состоять исключительно из цифр и начинаться с 8'">Телефон мобильный:</span>
                        <input type="number" v-model="modelContactPhone" class="apply__body__item__input" :class="{'not-valid': contactForm.phone.error}">
                        <span>На указанный номер будет выслан код подтверждения</span>
                      </label>
                      <label class="apply__body__item _col-2">
                        <span v-tooltip="'Подсказка: пример электронной почты qwerty@qwerty.ru'">E-mail:</span>
                        <input type="text" v-model="modelContactEmail" class="apply__body__item__input" :class="{'not-valid': contactForm.email.error}">
                      </label>
                    </div>
                  </div>
                  <div class="apply__body__contact__back">
                    <BackAvatarImage/>
                  </div>
                </div>
              </form>
              <div v-if="$store.state.step === 3" class="apply__body__end">
                <div class="apply__body__end__icon">
                  <DoubleCheckIcon/>
                </div>
                <div class="apply__body__end__content">
                  <span>Ваша заявка принята на рассмотрение. В течение 15-ти минут Вы получите решение по одобренному лимиту <router-link :to="{name: 'home'}">на рабочем столе</router-link> Вашего личного кабинета.</span>
                  <br>
                  <span>Если у Вас остались вопросы, то Вы можете задать его в разделе <router-link :to="{name: 'faq'}">FAQ</router-link></span>
                </div>
              </div>
              <div class="ct--client__buttons">
                <button v-if="$store.state.step === 1" type="button" class="btn" @click="checkCompanyForm(2)">Далее</button>
                <button v-else-if="$store.state.step === 2" type="button" class="btn" @click="changeStep(1)">Назад</button>
                <button v-if="$store.state.step === 2" type="button" class="btn" @click="checkCompanyForm(2); checkContactForm(3)">Отправить заявку</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>

import LineItem from '@/components/svg/LineItem'
import UserPlusIcon from '@/components/svg/UserPlusIcon'
import CheckIcon from '@/components/svg/CheckIcon'
import CarbonUserAvatarIcon from '@/components/svg/CarbonUserAvatarIcon'
import BackAvatarImage from '@/components/svg/BackAvatarImage'
import DoubleCheckIcon from '@/components/svg/DoubleCheckIcon'
import ALL_CLIENT_CONTACTS from '@/graphql/queries/AllClientContacts.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ALL_CLIENT_LEADS from '@/graphql/queries/AllClientLeads.gql'
import PARTNER from '@/graphql/queries/PartnerForUid.gql'
import PARTNER_INN from '@/graphql/queries/PartnerForInn.gql'
import axios from 'axios'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Cabinet',
  components: {
    LineItem,
    UserPlusIcon,
    CheckIcon,
    CarbonUserAvatarIcon,
    BackAvatarImage,
    DoubleCheckIcon
  },
  computed: {
    modelContactPassportId: {
      get () {
        return this.contactForm.passportId.value
      },
      set (value) {
        if (!(/^\d+$/).test(value)) {
          this.contactForm.passportId.error = true
        } else {
          this.contactForm.passportId.error = false
        }
        this.contactForm.passportId.value = value
      }
    },
    modelContactInn: {
      get () {
        return this.contactForm.inn.value
      },
      set (value) {
        if (value.length !== 12 || !(/^\d+$/).test(value)) {
          this.contactForm.inn.error = true
        } else {
          this.contactForm.inn.error = false
        }
        this.contactForm.inn.value = value
      }
    },
    modelContactPhone: {
      get () {
        return this.contactForm.phone.value
      },
      set (value) {
        if (!(/^\d+$/).test(value)) {
          this.contactForm.phone.error = true
        } else if (value[0] !== '8') {
          this.contactForm.phone.error = true
        } else {
          this.contactForm.phone.error = false
        }
        this.contactForm.phone.value = value
      }
    },
    modelContactEmail: {
      get () {
        return this.contactForm.email.value
      },
      set (value) {
        if (!(/\S+@\S+\.\S+/).test(value)) {
          this.contactForm.email.error = true
        } else {
          this.contactForm.email.error = false
        }
        this.contactForm.email.value = value
      }
    },
    modelPartnerInn: {
      get () {
        return this.companyForm.inn.value
      },
      set (value) {
        if (value.length !== 10 || !(/^\d+$/).test(value)) {
          this.companyForm.inn.error = true
        } else {
          this.companyForm.inn.error = false
        }
        this.companyForm.inn.value = value
      }
    },
    modelPartnerKpp: {
      get () {
        return this.companyForm.kpp.value
      },
      set (value) {
        if (value.length !== 9 || !(/^\d+$/).test(value)) {
          this.companyForm.kpp.error = true
        } else {
          this.companyForm.kpp.error = false
        }
        this.companyForm.kpp.value = value
      }
    },
    modelPartnerBik: {
      get () {
        return this.companyForm.bik.value
      },
      set (value) {
        if (value.length !== 9 || !(/^\d+$/).test(value)) {
          this.companyForm.bik.error = true
        } else {
          this.companyForm.bik.error = false
        }
        this.companyForm.bik.value = value
      }
    },
    modelPartnerRs: {
      get () {
        return this.companyForm.rs.value
      },
      set (value) {
        if (value.length !== 20 || !(/^\d+$/).test(value)) {
          this.companyForm.rs.error = true
        } else {
          this.companyForm.rs.error = false
        }
        this.companyForm.rs.value = value
      }
    },
    modelPartnerKs: {
      get () {
        return this.companyForm.ks.value
      },
      set (value) {
        if (value.length !== 20 || !(/^\d+$/).test(value)) {
          this.companyForm.ks.error = true
        } else {
          this.companyForm.ks.error = false
        }
        this.companyForm.ks.value = value
      }
    },
    modelPartnerPhone: {
      get () {
        return this.companyForm.phone.value
      },
      set (value) {
        if (!(/^\d+$/).test(value)) {
          this.companyForm.phone.error = true
        } else {
          this.companyForm.phone.error = false
        }
        this.companyForm.phone.value = value
      }
    },
    modelPartnerEmail: {
      get () {
        return this.companyForm.email.value
      },
      set (value) {
        if (!(/\S+@\S+\.\S+/).test(value)) {
          this.companyForm.email.error = true
        } else {
          this.companyForm.email.error = false
        }
        this.companyForm.email.value = value
      }
    }
  },
  data () {
    return {
      title: 'Подать заявку',
      minimized: false,
      step: 1,
      loaded: false,
      company: null,
      contact: null,
      partner: null,
      companyForm: {
        id: {
          value: '1',
          error: false,
          title: ''
        },
        name: {
          value: '',
          error: false,
          title: 'Наименование'
        },
        fullname: {
          value: '',
          error: false,
          title: 'Полное наименование'
        },
        inn: {
          value: '',
          error: false,
          title: 'ИНН'
        },
        kpp: {
          value: '',
          error: false,
          title: 'КПП'
        },
        bank: {
          value: '',
          error: false,
          title: 'Банк'
        },
        bik: {
          value: '',
          error: false,
          title: 'БИК'
        },
        rs: {
          value: '',
          error: false,
          title: 'Расчетный счет'
        },
        ks: {
          value: '',
          error: false,
          title: 'Корреспондентский счет'
        },
        adresU: {
          value: '',
          error: false,
          title: 'Юридический адрес'
        },
        adresF: {
          value: '',
          error: false,
          title: 'Фактический адрес'
        },
        adresD: {
          value: '',
          error: false,
          title: 'Почтовый адрес'
        },
        phone: {
          value: '',
          error: false,
          title: 'Телефон'
        },
        email: {
          value: '',
          error: false,
          title: 'Email'
        }
      },
      contactForm: {
        adresD: {
          value: '',
          error: false,
          title: 'Адрес для информирования'
        },
        basis: {
          value: '',
          error: false,
          title: 'Основа полномочий'
        },
        birthday: {
          value: '',
          error: false,
          title: 'Дата рождения'
        },
        birthplace: {
          value: '',
          error: false,
          title: 'Место рождения'
        },
        email: {
          value: '',
          error: false,
          title: 'E-mail'
        },
        id: {
          value: '1',
          error: false,
          title: ''
        },
        inn: {
          value: '',
          error: false,
          title: 'ИНН'
        },
        name: {
          value: '',
          error: false,
          title: 'ФИО'
        },
        passportCode: {
          value: '',
          error: false,
          title: 'Код подразделения'
        },
        passportDate: {
          value: '',
          error: false,
          title: 'Дата выдачи паспорта'
        },
        passportId: {
          value: '',
          error: false,
          title: 'Серия и номер паспорта'
        },
        passportPlace: {
          value: '',
          error: false,
          title: 'Место выдачи паспорта'
        },
        phone: {
          value: '',
          error: false,
          title: 'Телефон мобильный'
        },
        post: {
          value: '',
          error: false,
          title: 'Должность'
        }
      },
      calc: null,
      files: [],
      pageTitle: ['Контрагент:', 'Данные руководителя организации:'],
      loadingData: true
    }
  },
  beforeDestroy () {
    this.changeStep(1)
  },
  methods: {
    doneLoadingContracts ({ data, loading }) {
      const partnerId = this.$store.state.me.ref_id ? this.$store.state.me.ref_id[this.$store.state.me.ref_id.length - 1] : null
      this.getPartner(partnerId)

      // if (data.allContracts.length > 0) {
      //   let ids = data.allContracts.map(element => {
      //     element = element.partner.id
      //     return element
      //   })
      //   ids = [ ...new Set(ids) ]
      //   this.getLead()
      //   this.getContacts(ids[0])
      //   this.loadingData = false
      // } else {
      //   this.getPartnerForInn()
      // }
      this.getPartnerForInn()
      this.loadingData = false
    },
    getPartnerForInn () {
      this.$apollo.mutate({
        mutation: PARTNER_INN,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            Object.keys(data.PartnerForInn.Kompany[0]).forEach(elem => {
              if (this.companyForm[elem]) {
                if (elem === 'adresP') {
                  this.companyForm.adresD.value = data.PartnerForInn.Kompany[0][elem]
                } else if (elem !== '__typename' && elem !== 'id') {
                  this.companyForm[elem].value = data.PartnerForInn.Kompany[0][elem]
                }
              }
            })
            Object.keys(data.PartnerForInn.Kontact[0]).forEach(elem => {
              if (this.contactForm[elem] && elem !== '__typename' && elem !== 'KontactId' && elem !== 'agreement') {
                if (elem === 'birthday' || elem === 'passportDate') this.contactForm[elem].value = data.PartnerForInn.Kontact[0][elem].split('T')[0]
                else if (elem === 'name') this.contactForm.name.value = `${data.PartnerForInn.Kontact[0].surname} ${data.PartnerForInn.Kontact[0].name} ${data.PartnerForInn.Kontact[0].patron}`
                else this.contactForm[elem].value = data.PartnerForInn.Kontact[0][elem]
              }
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getPartner (uid) {
      if (!uid) return
      this.$apollo.mutate({
        mutation: PARTNER,
        variables: {
          partner_id: uid
        },
        update: (store, { data }) => {
          if (data) {
            Object.keys(data.PartnerForUid).forEach(elem => {
              if (elem !== '__typename' && elem !== 'id') this.companyForm[elem].value = data.PartnerForUid[elem]
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getContacts (companyIds) {
      this.$apollo.mutate({
        mutation: ALL_CLIENT_CONTACTS,
        variables: {
          companyIds
        },
        update: (store, { data }) => {
          if (data) {
            this.contact = data.allClientContacts[0]
            this.$store.state.contactInfo = data.allClientContacts[0]
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getLead () {
      this.$apollo.mutate({
        mutation: ALL_CLIENT_LEADS,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            this.$store.state.applyData = data.allClientLeads.Company
            Object.keys(data.allClientLeads.Company).forEach(field => {
              if (field === 'contact') {
                Object.keys(data.allClientLeads.Company.contact).forEach(fieldContact => {
                  if (this.contactForm[fieldContact] && data.allClientLeads.Company.contact[fieldContact]) {
                    if (fieldContact === 'name') {
                      this.contactForm[fieldContact].value = `${data.allClientLeads.Company.contact.surname} ${data.allClientLeads.Company.contact.name} ${data.allClientLeads.Company.contact.patron}`
                    } else if (fieldContact === 'passportDate' || fieldContact === 'birthday') {
                      this.contactForm[fieldContact].value = data.allClientLeads.Company.contact[fieldContact].split('T')[0]
                    } else if (fieldContact === 'phone') {
                      this.contactForm[fieldContact].value = data.allClientLeads.Company.contact[fieldContact].replace(/[^\d]/g, '')
                    } else {
                      this.contactForm[fieldContact].value = data.allClientLeads.Company.contact[fieldContact]
                    }
                  }
                })
              } else if (field === 'phone') {
                this.companyForm[field].value = data.allClientLeads.Company[field].replace(/[^\d]/g, '')
              } else {
                if (field !== 'files' && field !== 'filetypes') {
                  if (this.companyForm[field]) {
                    this.companyForm[field].value = data.allClientLeads.Company[field]
                  }
                }
              }
            })
            this.$store.state.infoFrom1C = {
              company: this.companyForm,
              contact: this.contactForm
            }
            this.loadingData = false
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    changeStep (num) {
      this.saveData()
      if (num === 3) {
        this.$store.state.smsModal = true
        this.pageTitle = ''
        return
      }
      this.step = num
      this.$store.state.step = num
    },
    checkCompanyForm () {
      let result = true
      let error = false
      let errorText = 'Не все поля заполнены'
      for (let key in this.companyForm) {
        if (
          this.companyForm[key]['value'] === '' &&
          !this.companyForm.adresD &&
          !this.companyForm.adresP &&
          !this.companyForm.name &&
          !this.companyForm.fullname &&
          !this.companyForm.kpp &&
          !this.companyForm.ks &&
          !this.companyForm.bank
        ) {
          this.companyForm[key].error = true
          result = false
        } else {
          this.companyForm[key].error = false
        }
        if (this.companyForm[key]['error'] === true) {
          errorText = `В поле "${this.companyForm[key]['title']}" введены неверные данные`
          error = true
        }
      }
      if (result && !error) this.changeStep(2)
      else {
        this.$notify({
          group: 'lsg-notify',
          text: `${errorText}`
        })
        this.changeStep(1)
      }
    },
    checkContactForm () {
      let result = true
      let error = false
      let errorText = 'Не все поля заполнены'
      for (let key in this.contactForm) {
        if (this.contactForm[key]['value'] === '') {
          this.contactForm[key].error = true
          result = false
        } else {
          this.contactForm[key].error = false
        }
        if (this.contactForm[key]['error'] === true) {
          errorText = `В поле "${this.contactForm[key]['title']}" введены неверные данные`
          error = true
        }
      }
      if (result && !error) {
        this.changeStep(3)
        this.$store.state.clientPhone = this.contactForm.phone
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: `${errorText}`
        })
      }
    },
    sendSms (phone) {
      const smsCode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
      this.$store.state.smsCode = smsCode
      const text = `Vash code: ${smsCode} dlya podpisania Soglasheniya ob ispolsovanii prostoi elektronoy podpisi https://leasing-trade.ru/documents/soglashenie.pdf`
      const BASE_URL = `${process.env.VUE_APP_HTTP}`
      const url = `${BASE_URL}/sms`
      axios.post(url, { text: text, phone: phone }).then(res => {
        if (res.status === 200) {
          return true
        } else return false
      })
    },
    saveData () {
      if (this.$store.state.step === 1) {
        this.$store.state.companyForm = this.companyForm
      } else if (this.$store.state.step === 2) {
        this.$store.state.contactForm = this.contactForm
      }
    }
  }
}
</script>

<style lang="stylus">
.not-valid {
  border-color: red !important;
  border-width: 2px;
  box-shadow: inset 0 0 0.1em 0 #FF0000, 0 0 0.1em 0 #FF0000;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance none !important
}

input[type=number] {
  -moz-appearance textfield !important
}
._row--apply {
  margin-top -10px !important
  margin-bottom 10px !important
}

.ellipse {
  display flex
  justify-content center
  align-items center
  height 50px
  width 50px
  border 1px solid $darkgray
  border-radius 50%
  transition opacity 0.25s
  cursor pointer

  &:hover {
    opacity 0.7
  }

  &--active  {
    background-color #ff7700
    border none

    > svg {
      path[fill] {
        fill $white
      }
    }
  }
}

.apply {
  display flex
  justify-content center
  flex-direction column
  align-items center

  &__body {
    display flex
    flex-direction column
    align-items center
    padding 32px
    width 90%
    max-width 1050px
    background $white
    border 1px solid $pinky
    box-sizing border-box
    border-radius 15px
    margin-top 15px
    box-shadow alpha($dark, 20%) 0 30px 60px

    +below(1140px) {
      &__contact {
        &__content {
          width 100% !important
        }
        &__back {
          display none !important
        }
      }
    }

    &__counterparty {
      display flex
      flex-direction row
      padding 0 !important
    }

    &__contact {
      display flex
      flex-direction row
      justify-content space-between

      &__content {
        display flex
        flex-direction column
        width 70%
      }

      &__back {
        display flex
        align-items center
      }
    }

    &__end {
      display flex
      flex row nowrap

      +below(680px) {
        flex-direction column-reverse
        align-items center
      }

      &__icon {
        display block
        min-width 176px
      }

      &__content {
        margin-left 20px
        display flex
        flex-direction column
        align-items flex-start
        justify-content center

        > span > a {
          color $blue
        }
      }
    }

    &__form {
      width 100%
    }

    &__item {
      display block
      width 100%
      margin-bottom 10px

      > strong
      > span {
        color #7a7a7a

        > a {
          cursor pointer
        }
      }

      &__input {
        margin-top 10px
      }
    }

    &__minimized {
      display flex
      flex-direction column
      align-items center
      padding 15px
      background #ff8000
      border 1px solid $pinky
      box-sizing border-box
      box-shadow none
      border-radius 10px
      width auto
      z-index 1000
    }
  }

  &__header {
    display flex
    flex-direction column
    padding 15px
    background $white
    border 1px solid $pinky
    box-sizing border-box
    box-shadow none
    border-radius 15px
    align-content center
    align-self center
    min-width 900px
    width 59.5%
    +below(1140px) {
      width 90%
      min-width auto
      .bn--client__header__textitem {
        display none
      }
    }

    &__item
    &__text {
      display flex
      flex-direction row
      width 100%
      justify-content space-between
    }
  }

  &__title {
    font-weight bold
    font-size 15px
    width 100%

    .fa-chevron-up
    .fa-chevron-down {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      margin-right 1rem
      float right

      &:hover {
        opacity 0.7
      }
    }

    .fa-times {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      float right

      &:hover {
        opacity 0.7
      }
    }
    .bn__title {
      transition opacity 0.25s
      color gray
      cursor pointer
      margin-right 2rem

      &:hover {
        opacity 0.7
      }
    }
  }
}

  .bn--client {
    display flex
    flex-direction column
    width 100%
  }

  .bn--client__col {
    width 45%
    margin-left 3rem
  }

  @media only screen and (max-width: 757px) {
    .bn--client__col {
      width 100%
    }
  }

  .bn--client__inputs {
    display flex
    flex-direction column
    width 100%
  }

  .bn--client__body {
    width 90%
    margin-top 2rem
  }

  .bn--client__body__header {
    text-align center
    font-family font_regular
  }
  .bn--client__header__container {
    display flex
    flex-direction row
  }
  .bn--client__header__textitem {
    margin 10px 10px 0 10px
  }
  .bn--client__header__textitem
  .bn--client__header__item {
    transition opacity 0.25s
    color darkgray
    cursor pointer

    &--active {
      color #ff7700
      fill #ff7700

      path {
        stroke $white
      }
    }
    &:hover {
      opacity 0.7
    }
  }
  @media only screen and (max-width: 800px) {
    .bn--client__header__textitem {
      font-size 0.8rem
    }
  }

.ct--client {
  display flex
  flex-direction column

  &__doc {
    display flex
    flex-direction column
    margin-bottom 30px

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      margin: 0 0 15px;
    }

    &__link {
      display inline-flex
      align-items center

      div {
        margin-right 10px

        svg {
          width 10px
          height 10px
        }
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: $orange;
      }
    }

    &__type {
      background: rgba($darkgray, 0.3);
      border-radius: 5px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: $black;
      padding 6px 13px;
      min-width 140px
      +below(768px) {
        width 100%
        margin-top 10px
      }
    }

    .v-select {
      width auto
    }

    &__drop {
      width 100%
      display flex
      align-items center
      margin-top 20px

      .dz-preview {
        display none
      }

      .dz-clickable {
        width 100%
      }

      .lk__dz {
        width 100%
      }
    }

    &__list {
      display flex
      flex-direction column
      padding 0
      background: $white;
      border: 1px solid $pinky;
      box-sizing: border-box;
      border-radius: 10px;
      width 100%

      &__item {
        padding 15px 20px
        display flex
        align-items center
        justify-content space-between
        +below(768px) {
          flex-direction column
          align-items flex-start
          justify-content flex-start
        }

        &:hover {
          background rgba($darkgray, 0.3);
        }

        &:not(:last-child) {
          border-bottom 1px solid $pinky
        }

        input {
          visibility hidden
          opacity 0
          width 0 !important
          height 0 !important
        }

        &.ct__doc__nl {
          > span {
            color $red
          }

          > label
          > a {
            color $red
          }
        }
      }
    }

    &s {
      display flex
      flex-direction column
      max-width 640px
      width 100%
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    padding 20px 32px 20px 40px
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom 10px
    position relative
    +below(960px) {
      flex-direction column
    }

    &:after {
      content ""
      width calc(100% - 80px)
      absolute top left 40px bottom
      height 2px
      background $darkgray
      align-self center
      margin auto
      +below(960px) {
        display none
      }
    }

    &__item {
      display inline-flex
      align-items center
      background $white
      z-index 1
      padding 0 20px
      +below(960px) {
        width 100%
        padding 0
        margin 10px 0
      }

      &:first-child {
        padding-left 0
      }

      &:last-child {
        padding-right 0
      }

      i {
        width 50px
        height 50px
        display flex
        align-items center
        justify-content center
        border 1px solid $orange
        border-radius 100%
        background $orange

        svg {
          width 24px
          height 24px

          path[stroke] {
            stroke $white
          }

          path[fill] {
            fill $white
          }
        }
      }

      span {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color $orange
        margin-left 10px
      }

      &--active {
        & ~ .ct__header__item {
          i {
            background $white
            border-color $darkgray

            svg {
              path[stroke] {
                stroke $c7A
              }

              path[fill] {
                fill $c7A
              }
            }
          }

          span {
            color rgba($black, 0.3)
          }
        }
      }
    }
  }

  &__body {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba($dark, 0.17);
    border-radius: 10px;
    display flex
    flex-direction column
    padding 30px 40px 40px

    &__header {
      font-family font_regular
      font-weight 500
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom 28px
    }
  }

  &__form {
    display flex
    align-items center
  }

  &__col {
    display flex
    flex-direction column
    width 100%
  }

  &__col-2 {
    display flex
    flex-direction column
    width calc(50% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  &__col-3 {
    display flex
    flex-direction column
    width calc(33.333333% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  &__col-4 {
    display flex
    flex-direction column
    width calc(25% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  .checkbox-component {
    margin-left 10px
    margin-top 15px

    label {
      display flex
      align-items center

      span:not(.input-box) {
        margin-left 10px
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: $black;
      }
    }
  }

  &__bottom {
    display flex
    flex-direction column
    width 100%

    .ct__message {
      margin 0 0 15px
      text-align left
    }
  }

  &__buttons {
    display flex
    flex-wrap wrap
    justify-content flex-start
    width 100%

    +below(1140px) {
      width 100%
    }

    > .btn {
      max-height 40px

      &:first-child {
        background $dark
        border-color $dark
        margin-right 20px
        margin-bottom 10px
      }

      &.hidden {
        cursor default
        opacity 0
      }

      i {
        width 16px
        height 16px
        margin-left 10px

        svg {
          width 100%
          height 100%

          path[stroke] {
            stroke $white !important
          }

          path[fill] {
            fill $white !important
          }
        }
      }
    }
  }

  &__message {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    margin 5px -10px
    color: $red;
    text-align right
    width 100%
  }

  &__row {
    display flex
    align-items flex-end
    justify-content space-between
    width calc(100% + 20px)
    margin 0 -10px
    flex-wrap: wrap;

    > * {
      margin-left 10px !important
      margin-right 10px !important
    }

    .btn {
      background: rgba($darkgray, 0.3);
      border: 1px solid $darkgray;
      color $c4E555A
      flex-shrink 0
      margin 10px 0

      svg {
        width 18px
        height 18px

        path {
          stroke $c4E555A
        }
      }
    }
  }

  &__input {
    display flex
    flex-direction column
    align-items flex-start
    width 100%
    margin 10px 0

    &.required {
      > input
      select
      .vs__dropdown-toggle
      .graph-search-input input
      .mx-datepicker input
      .field input
      textarea {
        border-color $red !important
      }
    }

    > label {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $c7A;
      margin-bottom 10px
    }

    .graph-search-input
    .field
    .mx-datepicker
    .v-select {
      width 100%
    }

    > input
    select
    .vs__dropdown-toggle
    .graph-search-input input
    .mx-datepicker input
    .field input
    textarea {
      resize vertical
      width 100%
      background: $white !important;
      border: 1px solid $c7A !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
    }

    &s {
      max-width 640px
      width 100%
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      margin -10px 0
      +below(1120px) {
        max-width 100%
      }
    }
  }

  &__back {
    display flex
    align-items center
    justify-content center
    width: 100%;
    +below(1120px) {
      display none
    }

    img {
      width auto
    }
  }
}

</style>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3165 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28472 19.7782 4.22182C17.7153 2.15892 14.9174 1 12 1ZM7.28572 20.1557V18.5843C7.25121 17.9208 7.47909 17.2703 7.92012 16.7734C8.36116 16.2765 8.97996 15.9731 9.64286 15.9286H14.3571C15.0215 15.9731 15.6415 16.2776 16.0828 16.7762C16.524 17.2749 16.7509 17.9273 16.7143 18.5921V20.1636C15.2831 20.9973 13.6564 21.4366 12 21.4366C10.3437 21.4366 8.71693 20.9973 7.28572 20.1636V20.1557ZM18.2857 19.0086V18.5293C18.3071 17.4581 17.9077 16.4212 17.1732 15.6411C16.4387 14.8611 15.4277 14.4001 14.3571 14.3571H9.64286C8.57161 14.3981 7.55938 14.8585 6.82447 15.639C6.08955 16.4194 5.69083 17.4575 5.71429 18.5293V18.9929C4.29102 17.7198 3.28782 16.0447 2.83744 14.189C2.38706 12.3334 2.51073 10.3847 3.19209 8.60089C3.87345 6.81707 5.08038 5.28217 6.65317 4.1993C8.22596 3.11644 10.0905 2.53664 12 2.53664C13.9095 2.53664 15.774 3.11644 17.3468 4.1993C18.9196 5.28217 20.1266 6.81707 20.8079 8.60089C21.4893 10.3847 21.6129 12.3334 21.1626 14.189C20.7122 16.0447 19.709 17.7198 18.2857 18.9929V19.0086Z" fill="#B5B5B5"/>
<path d="M12 5.25C11.2583 5.25 10.5333 5.46993 9.91661 5.88199C9.29993 6.29404 8.81928 6.87971 8.53545 7.56494C8.25162 8.25016 8.17736 9.00416 8.32206 9.73159C8.46675 10.459 8.8239 11.1272 9.34835 11.6517C9.8728 12.1761 10.541 12.5333 11.2684 12.6779C11.9958 12.8226 12.7498 12.7484 13.4351 12.4645C14.1203 12.1807 14.706 11.7001 15.118 11.0834C15.5301 10.4667 15.75 9.74168 15.75 9C15.75 8.00544 15.3549 7.05161 14.6517 6.34835C13.9484 5.64509 12.9946 5.25 12 5.25ZM12 11.25C11.555 11.25 11.12 11.118 10.75 10.8708C10.38 10.6236 10.0916 10.2722 9.92127 9.86104C9.75098 9.4499 9.70642 8.9975 9.79323 8.56105C9.88005 8.12459 10.0943 7.72368 10.409 7.40901C10.7237 7.09434 11.1246 6.88005 11.561 6.79323C11.9975 6.70642 12.4499 6.75097 12.861 6.92127C13.2722 7.09157 13.6236 7.37996 13.8708 7.74997C14.118 8.11998 14.25 8.55499 14.25 9C14.25 9.59674 14.0129 10.169 13.591 10.591C13.169 11.0129 12.5967 11.25 12 11.25Z" fill="#B5B5B5"/>
</svg>
</template>

<script>
export default {
  name: 'CarbonUserAvatarIcon'
}
</script>

<template>
  <svg :class="{'LineItem--client': this.$store.state.me.role === 'partner'}" class="LineItem" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <line x1="-450" y1="16" x2="450" y2="16"/>
  </svg>
</template>

<script>
export default {
  name: 'LineItem'
}
</script>

<style lang="stylus">
.LineItem {
  display flex
  width 25rem
  height 1rem
  stroke darkgray
  stroke-width 0.3rem
}

.LineItem--client {
  display flex
  width 17% !important
  margin-top 17px
  height 1rem
  stroke darkgray
  stroke-width 0.3rem

  +below(1140px) {
    width 25% !important
  }

  +below(700px) {
    width 17% !important
  }
}
</style>
